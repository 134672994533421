import { put } from 'redux-saga/effects';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import axios from '../../../utils/axios';
import { getErrorMessage, hasError, startFormLoading, stopFormLoading } from '../../slices/responseStatus';
import {
  downloadAttendanceReport,
  downloadAttendanceReportStatus,
  getAttendanceList,
  getAttendanceListCount,
  startAttendanceListLoading,
  startLoading,
} from '../../slices/employee';
import { isLogout } from '../../slices/login';

export function* employeeAttendanceListSaga(state) {
  try {
    const { startDate, endDate, rowsPerPage, page, userID, order, orderBy, filterName } = state;
    yield put(hasError(false));
    yield put(startAttendanceListLoading(true));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const sortParams = order && orderBy ? `&sort=${order}&column=${orderBy}` : '';
    const searchTextParams = filterName && filterName.trim() !== '' ? `&searchText=${filterName}` : '';

    let queryString = `?startDate=${startDate}&endDate=${endDate}&limit=${rowsPerPage}&page=${
      page + 1
    }${sortParams}${searchTextParams}`;

    if (userID) {
      queryString += `&userID=${userID}`;
    }

    const response = yield axios.get(`${BASEURL}/attendance/report${queryString}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const { attendanceList, totalRowsValue } = response.data.data.Attendance;
    const count = totalRowsValue;
    yield put(getAttendanceList(attendanceList === undefined ? [] : attendanceList));
    yield put(getAttendanceListCount(count));
    yield put(stopFormLoading());
    yield put(startAttendanceListLoading(false));
    // yield put(downloadAttendanceReport(attendanceList === undefined ? [] : attendanceList));
  } catch (error) {
    yield put(startAttendanceListLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}
export function* downloadAttendancetReportSaga(state) {
  try {
    yield put(hasError(false));
    yield put(startAttendanceListLoading(true));
    yield put(startFormLoading());
    const { startDate, endDate, order, orderBy, filterName, userID } = state;
    const Token = window.localStorage.getItem('auth-token');
    const searchTextParams = filterName && filterName.trim() !== '' ? `&searchText=${filterName}` : '';
    const userId = userID && userID !== undefined ? `&userID=${userID}` : '';
    const sortParams = order && orderBy ? `&sort=${order}&column=${orderBy}` : '';

    const response = yield axios.get(
      `${BASEURL}/attendance/report?startDate=${startDate || new Date().toISOString().substr(0, 10)}&endDate=${
        endDate || new Date().toISOString().substr(0, 10)
      }${searchTextParams}${userId}${sortParams}`,
      {
        headers: {
          'x-auth-token': `${Token}`,
        },
      }
    );
    const { attendanceList } = response.data.data.Attendance;
    yield put(downloadAttendanceReport(attendanceList === undefined ? [] : attendanceList));
    yield put(downloadAttendanceReportStatus(response.status));
    yield put(stopFormLoading());
    yield put(startAttendanceListLoading(false));
  } catch (error) {
    yield put(startAttendanceListLoading(false));
    yield put(stopFormLoading());
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
