import { put } from 'redux-saga/effects';
import axios from '../../../utils/axios';
import { BASEURL } from '../../../BaseUrl/BaseUrl';
import { isLogout } from '../../slices/login';
import {
  hasError,
  startFormLoading,
  getErrorMessage,
  stopFormLoading,
  setResponse,
  getSuccessMessage,
} from '../../slices/responseStatus';
import {
  getDeletedEmployeeByDesignationReducer,
  getUsersByStatusReducer,
  reduceUserFromRegistered,
  startLoading,
  startUserByStatusLoading,
} from '../../slices/employee';

export function* getUsersByStatusSaga(state) {
  try {
    const { status } = state;
    yield put(hasError(false));
    yield put(startUserByStatusLoading(true));
    yield put(getUsersByStatusReducer([]));
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/users/status?status=${status}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    yield put(startUserByStatusLoading(false));
    const usersData = response?.data?.data?.usersWithAuthority;
    yield put(getUsersByStatusReducer(usersData === undefined ? [] : usersData));
  } catch (error) {
    yield put(startUserByStatusLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
    if (error.error?.statusCode === 401) {
      yield put(isLogout(true));
    }
  }
}

export function* getDeletedEmployeeByDesignation(state) {
  try {
    const { designationId } = state;
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.get(`${BASEURL}/users/designation/deleted/${designationId}`, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    const deletedEmployee = response?.data?.data?.users?.data;
    yield put(getDeletedEmployeeByDesignationReducer(deletedEmployee === undefined ? [] : deletedEmployee));
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* assignDeletedUserSaga(state) {
  try {
    const { data } = state;
    yield put(hasError(false));
    yield put(startFormLoading());
    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/users/assignedeleteuser`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(reduceUserFromRegistered(data.id));
      yield put(getSuccessMessage(response.data.meta.message));
      yield put(setResponse(response.status));
    }
    yield put(stopFormLoading());
  } catch (error) {
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}

export function* rejectUserSaga(state) {
  try {
    yield put(hasError(false));
    const { id, data } = state;
    yield put(startLoading(true));

    const Token = window.localStorage.getItem('auth-token');
    const response = yield axios.post(`${BASEURL}/users/statusChange?status=rejected&id=${id}`, data, {
      headers: {
        'x-auth-token': `${Token}`,
      },
    });
    if (response.status === 200) {
      yield put(reduceUserFromRegistered(id));
    }
    yield put(startLoading(false));
  } catch (error) {
    yield put(startLoading(false));
    yield put(hasError(true));
    yield put(getErrorMessage(error.error.message));
  }
}
